<template>
<span v-if="delete_id">
 
    <v-btn small outlined color="red darken-3" @click="dialogDelete=true" v-if="item.delete_text">
        {{item.delete_text}}
    </v-btn>
    <v-btn icon color="red darken-3" @click="dialogDelete=true" v-else>
        <v-icon>mdi-close-circle-outline</v-icon>
    </v-btn>

    <v-dialog v-model="dialogDelete" scrollable max-width="400px">
        <v-card class="text-center">
            <v-toolbar dark color="red darken-4" height="40" style="max-height:40px;" rounded="0">

                ยืนยันการลบ

                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn icon dark @click="dialogDelete = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text class="pa-5 text-center">
                {{item.title}}
                <div v-if="loading" class="mb-2">
                    <v-progress-circular indeterminate color="red"></v-progress-circular>
                </div>
                <div v-if="item.delete_html" v-html="item.delete_html"></div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text class="pa-2 text-center">
                
                <v-btn color="blue darken-1" @click="dialogDelete = false" dark>
                    ยกเลิก
                </v-btn>
                <v-btn color="red darken-4" text @click="deleteItem()" v-if="!csCenter">
                    ยืนยัน
                </v-btn>

                <v-btn color="red darken-4" text @click="deleteItemCsCenter()" v-if="csCenter">
                    ยืนยัน
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>

</span>
</template>

<script>
export default {

    components: {

    },
    props: {

        url: null,
        item: null,
        delete_id: null,
        csCenter: {
            type: Boolean,
            default: false
        }

    },

    data: () => ({
loading:null,
        dialogDelete: null,
    }),

    computed: {

    },

    watch: {

    },
    methods: {
        deleteItemCsCenter() {

            let $vm = this;

            let data = {
                delete_id: this.delete_id
            }
            this.loading = true
            console.log('sssss')
            this.$apiCenterBack(this.url, data).then(function (data) {

                if (data.re == 'success') {
                    $vm.$emit('delete', true)
                }
                 $vm.loading = null

            })
        },

        deleteItem() {

            let $vm = this;

            let data = {
                delete_id: this.delete_id
            }
             this.loading = true
            this.$api(this.url, data).then(function (data) {

                if (data.re == 'success') {
                    $vm.$emit('delete', true)
                }
                 $vm.loading = null

                 $vm.dialogDelete = false;

            })

        }

    },
    created() {

    },

};
</script>
