import Vue from 'vue'
import VueRouter from 'vue-router'
 

Vue.use(VueRouter)



const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },

  {
    path: "/admin/:slug",
    name: "AdminDo",
    component: () => import("../views/Admin/Admin.vue"),
  },

  {
    path: "/admin/:slug/:item_id",
    name: "AdminId",
    component: () => import("../views/Admin/Admin.vue"),
  },

  {
    path: "/interior",
    name: "Interior",
    component: () => import("../views/Interior/Interior.vue"),
  },

  {
    path: "/dogs",
    name: "Dogs",
    component: () => import("../views/Dog/Dog.vue"),
  },

  {
    path: "/room",
    name: "Room",
    component: () => import("../views/Room/Room.vue"),
  },

  {
    path: "/environment",
    name: "Environment",
    component: () => import("../views/Environment/Environment.vue"),
  },

  

  {
    path: "/roomTexture",
    name: "RoomTexture",
    component: () => import("../views/RoomTexture/RoomTexture.vue"),
  },

 
  {
    path: "/stroke",
    name: "Stroke",
    component: () => import("../views/Stroke/Stroke.vue"),
  },
 
 
  {
    path: "/users",
    name: "User",
    component: () => import("../views/User/User.vue"),
  },
];

    Vue.prototype.$KEY = null;

const router = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  mode: "history",
  base: process.env.VUE_APP_DIR,
  routes,
});

router.beforeEach((to, from, next) => {
  Vue.prototype.$KEY = Math.random();

  if (to.matched.some((record) => record.meta.reuse === true)) {
    Vue.prototype.$KEY = null;
  } else {
    /*  if(from.name != 'Account') {
       Vue.prototype.$KEY = Math.random();
      } */
  }
  next();
});

export default router
